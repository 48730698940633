*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  font-family: Poppins, sans-serif;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose > :where(ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose > :where(ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.invisible {
  visibility: hidden;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.-bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.-left-14 {
  left: -3.5rem;
}

.right-\[-18px\] {
  right: -18px;
}

.-left-44 {
  left: -11rem;
}

.z-0 {
  z-index: 0;
}

.-z-50 {
  z-index: -50;
}

.m-8 {
  margin: 2rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-96 {
  height: 24rem;
}

.h-72 {
  height: 18rem;
}

.h-\[200px\] {
  height: 200px;
}

.h-0\.5 {
  height: .125rem;
}

.h-0 {
  height: 0;
}

.h-12 {
  height: 3rem;
}

.h-56 {
  height: 14rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-full {
  height: 100%;
}

.w-56 {
  width: 14rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-\[200px\] {
  width: 200px;
}

.w-72 {
  width: 18rem;
}

.w-\[42rem\] {
  width: 42rem;
}

.w-0 {
  width: 0;
}

.w-96 {
  width: 24rem;
}

.w-12 {
  width: 3rem;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[300px\] {
  width: 300px;
}

.w-1\/2 {
  width: 50%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.flex-1 {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.origin-center {
  transform-origin: center;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes blob {
  0% {
    transform: translate(0)scale(1);
  }

  33% {
    transform: translate(30px, -50px)scale(1.1);
  }

  66% {
    transform: translate(-20px, 20px)scale(.9);
  }

  100% {
    transform: tranlate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.justify-self-start {
  justify-self: start;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-circle {
  background-color: #20e6d642;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(32 231 214 / var(--tw-bg-opacity) );
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 39 / var(--tw-bg-opacity) );
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops) );
}

.from-blue-900 {
  --tw-gradient-from: #20e7d6;
  --tw-gradient-to: #20e7d600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-blue-100 {
  --tw-gradient-to: #0dc9b800;
  --tw-gradient-stops: var(--tw-gradient-from), #0dc9b8, var(--tw-gradient-to);
}

.to-blue-900 {
  --tw-gradient-to: #20e7d6;
}

.bg-cover {
  background-size: cover;
}

.bg-size-200 {
  background-size: 200% 200%;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-primary {
  fill: #20e7d6;
}

.fill-secondary {
  fill: #272727;
}

.p-6 {
  padding: 1.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 2.125rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.leading-tight {
  line-height: 1.25;
}

.leading-loose {
  line-height: 2;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(32 231 214 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-transparent {
  color: #0000;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.animation-delay-4000 {
  animation-delay: 10s;
}

*, html {
  scroll-behavior: smooth;
}

.noise-bg {
  z-index: -2;
  width: 200%;
  height: 200vh;
  opacity: .5;
  visibility: visible;
  background: url("noise-transparent.c5ec7e1c.png");
  animation: noise-bg-animation 1.6s infinite;
  position: fixed;
  inset: -50%;
}

.hover\:bg-right-bottom:hover {
  background-position: 100% 100%;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(32 231 214 / var(--tw-text-opacity) );
}

.hover\:drop-shadow-none:hover {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity) );
}

.group:hover .group-hover\:w-full {
  width: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.group:hover .group-hover\:animate-spin {
  animation: spin 1s linear infinite;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (prefers-color-scheme: dark) {
  .dark\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity) );
  }
}

@media (min-width: 640px) {
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md\:mt-80 {
    margin-top: 20rem;
  }

  .md\:mb-36 {
    margin-bottom: 9rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[600px\] {
    height: 600px;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-\[600px\] {
    width: 600px;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-3\/6 {
    width: 50%;
  }

  .md\:min-w-\[332px\] {
    min-width: 332px;
  }

  .md\:flex-1 {
    flex: 1;
  }

  @keyframes blob {
    0% {
      transform: translate(0)scale(1);
    }

    33% {
      transform: translate(30px, -50px)scale(1.1);
    }

    66% {
      transform: translate(-20px, 20px)scale(.9);
    }

    100% {
      transform: tranlate(0px, 0px) scale(1);
    }
  }

  .md\:animate-blob {
    animation: blob 7s infinite;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-5xl {
    font-size: 3.89rem;
  }

  .md\:text-3xl {
    font-size: 2.125rem;
  }

  .md\:text-4xl {
    font-size: 2.89rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-left-44 {
    left: -11rem;
  }

  .lg\:right-\[-26px\] {
    right: -26px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:left-44 {
    left: 11rem;
  }

  .xl\:right-\[-300px\] {
    right: -300px;
  }

  .xl\:left-0 {
    left: 0;
  }

  .group:hover .xl\:group-hover\:visible {
    visibility: visible;
  }

  .group:hover .xl\:group-hover\:invisible {
    visibility: hidden;
  }

  .group:hover .xl\:group-hover\:block {
    display: block;
  }

  .group:hover .xl\:group-hover\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .group:hover .xl\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .group:hover .xl\:group-hover\:opacity-0 {
    opacity: 0;
  }
}

/*# sourceMappingURL=app.css.map */
