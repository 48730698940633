@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.animation-delay-1000 {
		animation-delay: 8s;
	}
	.animation-delay-2000 {
		animation-delay: 8s;
	}
	.animation-delay-4000 {
		animation-delay: 10s;
	}
}

@layer base {
	html {
		font-family: 'Poppins', sans-serif;
	}
}

*,
html {
	scroll-behavior: smooth;
}

.noise-bg {
	position: fixed;
	z-index: -2;
	top: -50%;
	left: -50%;
	right: -50%;
	bottom: -50%;
	width: 200%;
	height: 200vh;
	background: transparent url('../../web/static/img/noise-transparent.png')
		repeat 0 0;
	background-repeat: repeat;
	animation: noise-bg-animation 1.6s infinite;
	opacity: 0.5;
	visibility: visible;
}
/* @keyframes noise-bg-animation {
	0% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(-5%, -5%);
	}
	20% {
		transform: translate(-10%, 5%);
	}
	30% {
		transform: translate(5%, -10%);
	}
	40% {
		transform: translate(-5%, 15%);
	}
	50% {
		transform: translate(-10%, 5%);
	}
	60% {
		transform: translate(15%, 0);
	}
	70% {
		transform: translate(0, 10%);
	}
	80% {
		transform: translate(-15%, 0);
	}
	90% {
		transform: translate(10%, 5%);
	}
	100% {
		transform: translate(5%, 0);
	}
} */
